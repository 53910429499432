<script>
import Swal from "sweetalert2";
import { eventBus } from "@/main.js";

import {
  axiosApi
} from '@/services/axios';

export default {
  methods: {
    importByID: () => {
      Swal.fire({
        title: 'Import your Spotify tracks',
        html: 'You can add tracks by entering the track id(s) into the field below.',
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: async (data) => {
            if (data === '') {
              Swal.showValidationMessage(`Track id(s) are required.`)
              return
            }
            const response = await axiosApi.post(`/tracks?tracks=${data.replace(" ", ",").replace(/(?:\r\n|\r|\n)/g, ',')}`, {}).catch((err) => {
              console.error(err);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                footer: 'Don\'t worry, we have been notified and are working on it.'
              })
            })
            return response
          },
          allowOutsideClick: false
      }).then((result) => {
        if (result.isDismissed) return
        Swal.fire({
          icon: "success",
          title: 'Tracks Imported!',
          allowOutsideClick: true,
          showConfirmButton: true,
          confirmButtonText: 'Bravo!'
        })
        if (result.value.errWrite.length > 0) {
          Swal.showValidationMessage(`Track(s) "${result.value.errWrite[1]}" failed to import - track already exists.`)
        }
        if (result.value.errTracks.length > 0) {
          Swal.showValidationMessage(`Some tracks failed to import - ${result.value.errTracks}.`)
        }
        eventBus.$emit('reloadTracks');
      });
    },
    importSpotify: async () => {
      window.location = `${process.env.VUE_APP_SUBS_ARTIST_BASE_API_URL}/spotify/auth`
    },
    reloadFunction: () => {
      eventBus.$emit('reloadTracks');
    },
  }
}
</script>

<template>
<span>
  <b-tooltip target="import-by-id" placement="bottom">Import by ID</b-tooltip>
  <b-button @click="importByID" id="import-by-id" variant="outline-secondary" class="ml-3"><i class="fas fa-plus-circle fa-lg"></i></b-button>
  <b-tooltip target="import-spotify" placement="bottom">Import from Spotify</b-tooltip>
  <b-button @click="importSpotify" id="import-spotify" variant="outline-secondary" class="ml-1"><i :class="platformIconMap('spotify')" class="fa-lg text-success"></i></b-button>
</span>
</template>

<style scoped>
.icon-demo-content {
  margin-right: unset !important;
}

</style>
