<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import tracksMenu from "./tracks-menu";
import { eventBus } from "@/main.js";

import {
  axiosApi
} from '@/services/axios';

import moment from 'moment';

/**
 * Shops Component
 */
export default {
  page: {
    title: "Tracks",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  created() {
    if (location.hash === '#spotify-import') this.spotifyImport();
    if (location.hash.startsWith('#spotify-import-success')) this.spotifyImportSuccess();
    this.getTracks();
    eventBus.$on('reloadTracks', () => {
      this.getTracks();
    })
  },
  data() {
    return {
      tracksMenu,
      title: "My Tracks",
      items: [
        {
          text: "99subs"
        },
        {
          text: "Tracks",
          active: true
        }
      ],
      tracksData: [],
      loading: false,
      loadingMore: false,
      showByIndex: null,
      page: 0
    };
  },
  methods: {
    async getTracks(loadMore = false) {
      this.loading = true
      await axiosApi.get(`/tracks?fields=name id images on_campaign campaign_id platform created_at metadata.external_urls.spotify&limit=16&sort=created_at:-1&page=${this.page}`).then(response => {
        if (!loadMore) this.tracksData = []
        response.forEach((item) => {
          let trackData = {}
          trackData.id = item.id
          trackData.img = item.images[0].url
          trackData.name = item.name
          trackData.href = item.metadata.external_urls.spotify
          trackData.tagNew = moment(new Date()).diff(item.created_at, 'days') < 5 ? true : false
          trackData.platform = item.platform
          trackData.icon = this.platformIconMap(item.platform)
          trackData.onCampaign = item.on_campaign
          trackData.campaignID = item.campaign_id
          this.tracksData.push(trackData)
        });
        this.loading = false
      })
    },
    spotifyImportSuccess () {
      window.location.hash = ''
      window.history.pushState(null, "", window.location.href.replace("#", ""));
      Swal.fire({
        icon: "success",
        title: 'Tracks Imported!',
        allowOutsideClick: true,
        showConfirmButton: true,
        confirmButtonText: 'Bravo!'
      })
    },
    async spotifyImport() {

      Swal.fire({
        title: 'Importing your Spotify tracks',
        showConfirmButton: false,
        willOpen: async () => {
          Swal.showLoading ()
        },
        allowOutsideClick: false
      })

      const result = await axiosApi.get(`/tracks/spotify`).catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Don\'t worry, we have been notified and are working on it.'
        })
      })

      if (!result) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Don\'t worry, we have been notified and are working on it.'
        })
      }

      this.getTracks();

      Swal.fire({
        icon: "success",
        title: 'Tracks Imported!',
        allowOutsideClick: true,
        showConfirmButton: true,
        confirmButtonText: 'Bravo!'
      })
    },
    async loadMore() {
      this.loadingMore = true
      this.page += 1
      await this.getTracks(true);
      this.loadingMore = false
    },
    newCampaign(trackID, trackName) {
      console.log('newCampaign!');
      this.$router.push({
        path: '/campaign-checkout',
        query: {
          trackID: trackID,
          trackName: trackName
        }
      })
    },
    openCampaign(campaignID, campaignTitle) {
      this.$router.push({
        path: '/campaign',
        query: {
          id: campaignID,
          title: campaignTitle
        }
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :menuComponent="tracksMenu" />
    <div class="row">
      <div class="col-xl-3 col-sm-6" v-for="(data, index) in tracksData" :key="index">
        <div class="text-center">
          <div @mouseenter="showByIndex = index" @mouseleave="showByIndex = null" class="image-button-container">
            <transition name="fade">
              <b-button @click.prevent="data.onCampaign ? openCampaign(data.campaignID, data.name) : newCampaign(data.id, data.name)" v-show="showByIndex === index" :variant="data.onCampaign ? 'secondary' : 'accent'"><strong>{{ data.onCampaign ? "Open" : "Start" }} Campaign</strong></b-button>
            </transition>
            <img @click="data.onCampaign && openCampaign(data.campaignID, data.name)" :src="`${data.img}`" alt class="avatar-xl mt-2 mb-2 img-thumbnail-dark" :role="data.onCampaign ? 'button': ''"/>
          </div>
          <div class="media-body">
            <h6 class="text-truncate">
              <a @mouseenter="showByIndex = index" @mouseleave="showByIndex = null" @click.prevent="data.onCampaign && openCampaign(data.campaignID, data.name)" class="text-dark" :role="data.onCampaign ? 'button': ''">{{data.name}}</a>
            </h6>
            <div class="mb-3">
              <b-tooltip :target="`view-platform-${index}`" placement="bottom">Listen on {{ data.platform }}</b-tooltip>
              <a :href="data.href" target="_blank"><i :id="`view-platform-${index}`" :class="data.icon" class="fa-lg mr-2 text-success"></i></a>

              <b-tooltip :target="`open-campaign-${index}`" placement="bottom">Open Campaign</b-tooltip>
              <i :id="`open-campaign-${index}`" v-if="data.onCampaign" @click.prevent="data.onCampaign && openCampaign(data.campaignID, data.name)" class="fas fa-globe fa-lg mr-2 text-success" :role="data.onCampaign ? 'button': ''"></i>
              <b-badge v-if="data.tagNew" pill variant="info" class="badge-position">New</b-badge>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row" v-if="this.tracksData.length > 15">
      <div class="col-xl-12">
        <div class="text-center my-3">
          <b-button @click="loadMore" variant="outline-secondary">
            Load more
            <i v-if="loadingMore" class="mdi mdi-loading mdi-spin align-middle mr-2"></i>
          </b-button>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.avatar-xl {
  height: 15rem;
  width: 15rem;
}

.badge-position {
  position: relative;
  top: -2px;
}

.activity-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.image-button-container {
  position: relative;
}

.image-button-container > button {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
